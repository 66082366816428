import Remote from './remote';

export default class CommandsUI {
  constructor() {
    this.cmd = '';
    this.ids = [];
    this.remote = new Remote('/commands', {});
    this.attached = false;
  }

  bind_commands(ctx) {
    $('[data-command]', ctx).off('.commands_ui');
    $('[data-command]', ctx).on('click.commands_ui', (e) => {
      e.preventDefault();
      $('button[data-command]').removeClass('active');
      $(e.currentTarget).addClass('active');

      if ($('.index-container').attr('data-mode') != 'form') {
        $('.index-container').attr('data-mode', 'command');
      }

      this.cmd = $(e.currentTarget).attr('data-command');
      this.ids = false;
      let path = `${this.cmd}`;
      let data = {};

      let id = $(e.currentTarget).attr('data-id');
      if (id) {
        this.ids = [id];

        path = `ids/${this.cmd}`;
        data = { ids: [id] };
      }
      this.choose(path, data);
    });
  }

  detach() {
    $('#command-form').off('update');
    $('#command-form-panel form').off('submit');
    $('#commands-panel .panel-body').off('update');
    $('[data-command]').off('.commands_ui');
    this.attached = false;
  }

  attach() {
    if (this.attached) {
      this.detach();
    }

    this.attached = true;
    $('#command-form').on('update', (e) => {
      if ($('#command-form').text().trim() != '') {
        $('#command-form-panel').addClass('active');
        $('.index-container').attr('data-mode', 'form');
      } else {
        $('#command-form-panel').removeClass('active');
      }

      $('#command-form-panel form').off('submit');
      $('#command-form-panel form').on('submit', (e) => {
        e.preventDefault();

        let path = `${this.cmd}`;
        let data = $('#command-form-panel form').serialize();

        if (this.ids) {
          path = `ids/${this.cmd}`;
          $.each(this.ids, (idx, id) => {
            data += `&ids[]=${id}`;
          });
        }

        this.remote
          .post(path, data, { type: 'html' })
          .on('noAccess', () => {
            window.location.replace('/');
          })
          .then((result) => {
            $('#command-form').html(result);
            $('#command-form').trigger('update');
          })
          .catch((err) => {
            console.error(err);
          });
      });
    });

    $('#commands-panel .panel-body').on('update', (e) => {
      this.bind_commands(e.target);
    });
  }

  update_list() {
    $('#command-form').html('');
    $('#command-form-panel').removeClass('active');

    this.remote
      .get('', {}, { type: 'html' })
      .on('noAccess', () => {
        window.location.replace('/');
      })
      .then((result) => {
        let el = $('#commands-panel .panel-body');
        el.html(result);
        el.trigger('update');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  choose(path, data, cmd) {
    this.remote
      .get(path, data, { type: 'html' })
      .on('noAccess', () => {
        window.location.replace('/');
      })
      .then((result) => {
        let el = $('#command-form');
        el.html(result);
        el.trigger('update');
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
