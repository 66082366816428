import Remote from './remote';
import List from './list';

export default class ListUI {
  constructor(commands_ui, bind_modals) {
    this.remote = new Remote('/list', {});
    this.list = new List(this.remote);
    this.commands_ui = commands_ui;
    this.attached = false;
    this.bind_modals = bind_modals;
  }

  add(id) {
    return this.list.add(id);
  }

  remove(id) {
    return this.list.remove(id);
  }

  detach() {
    this.attached = false;
  }

  attach() {
    let self = this;
    if (this.attached) {
      this.detach();
    }

    this.attached = true;

    // search
    $('#search').on('submit.list_ui', function (e) {
      e.preventDefault();
      self.search(self.remote.get('search', $(this).serialize(), { type: 'html' }));
    });

    // search result
    $('#result-list').on('update', (e) => {
      self.bind_modals($('#result-list'));

      $('#result-list .pagination a').on('click.list_ui', (e) => {
        e.preventDefault();
        let path = $(e.currentTarget).attr('href').substring(6);

        self.search(this.remote.get(path, {}, { type: 'html' }));
      });

      $('.add_all').on('click.list_ui', (e) => {
        e.preventDefault();

        let ids = $.map($('#result-list tr[data-id]'), function (el) {
          $(
            '#result-list [data-type="' + $(el).attr('data-type') + '"][data-id="' + $(el).attr('data-id') + '"]'
          ).addClass('selected');
          return $(el).attr('data-type') + '-' + $(el).attr('data-id');
        });
        self.list
          .add(ids)
          .then((result) => {
            self.update_list(result);
          })
          .catch((err) => {
            console.error(err);
          });
      });

      $('.add').on('click.list_ui', (e) => {
        e.preventDefault();

        let el = $(e.currentTarget).parents('[data-id]');
        let id = el.attr('data-type') + '-' + el.attr('data-id');
        $('#result-list [data-type="' + el.attr('data-type') + '"][data-id="' + el.attr('data-id') + '"]').addClass(
          'selected'
        );
        self.list
          .add([id])
          .then((result) => {
            self.update_list(result);
          })
          .catch((err) => {
            console.error(err);
          });
      });
    });

    // list
    $('#list').on('update', (e) => {
      self.bind_modals($('#list'));

      if ($('#list td').length > 0) {
        $('#list-panel').addClass('active');
      } else {
        $('#list-panel').removeClass('active');
      }

      $('.remove_all').on('click.list_ui', (e) => {
        e.preventDefault();

        let ids = $.map($('#list tr[data-id]'), function (el) {
          $(
            '#result-list [data-type="' + $(el).attr('data-type') + '"][data-id="' + $(el).attr('data-id') + '"]'
          ).removeClass('selected');
          return $(el).attr('data-type') + '-' + $(el).attr('data-id');
        });
        if (ids.length > 0) {
          self.list
            .clean(ids)
            .then((result) => {
              self.update_list(result);
              self.commands_ui.update_list();
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });

      $('.remove').on('click.list_ui', (e) => {
        e.preventDefault();

        let el = $(e.currentTarget).parents('[data-id]');
        let id = el.attr('data-type') + '-' + el.attr('data-id');
        $('#result-list [data-type="' + el.attr('data-type') + '"][data-id="' + el.attr('data-id') + '"]').removeClass(
          'selected'
        );
        self.list
          .remove([id])
          .then((result) => {
            self.update_list(result);
            self.commands_ui.update_list();
          })
          .catch((err) => {
            console.error(err);
          });
      });
    });

    $('#list').trigger('update');
  }

  search(p) {
    p.on('noAccess', () => {
      window.location.replace('/');
    })
      .then((result) => {
        let el = $('#result-list');
        el.off('.list_ui', 'update');
        el.html(result);
        el.trigger('update');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  update_list(result) {
    let el = $('#list');
    el.off('.list_ui', 'update');
    el.html(result);
    el.trigger('update');
  }

  reload() {}
}
