export default class List {
  constructor(remote, options) {
    this.remote = remote;
    this.options = options;
    return this;
  }

  add(ids) {
    return this.remote.put('', { ids: ids }, { type: 'html' }).on('noAccess', () => {
      window.location.replace('/');
    });
  }

  remove(ids) {
    return this.remote.delete('', { ids: ids }, { type: 'html' }).on('noAccess', () => {
      window.location.replace('/');
    });
  }

  clean(ids) {
    return this.remote.delete('/clean', { ids: ids }, { type: 'html' }).on('noAccess', () => {
      window.location.replace('/');
    });
  }

  load() {
    return this.remote.get('load', {}, { type: 'html' }).on('noAccess', () => {
      window.location.replace('/');
    });
  }
}
