import $ from 'jquery';
import autocomplete from '../libs/bower/devbridge-autocomplete/dist/jquery.autocomplete.min.js';
import Modal from './modals';
import Vue from 'vue';

window.jQuery = $;
window.$ = $;
window.Vue = Vue;

import * as dt from 'datatables.net';
import * as dt_dt from 'datatables.net-dt';
