Vue.component('config_string', {
  props: ['config'],
  template: `
  <div class="form-group">
    <label>Attribute field</label>
    <input type="text" v-model="config.data.field" class="form-control" required />
  </div>`,
  methods: {},
});

Vue.component('config_array_of_strings', {
  props: ['config'],
  template: `
  <div class="form-group">
    <label>Attribute field</label>
    <input type="text" v-model="config.data.field" class="form-control" required />
  </div>`,
  methods: {},
});

Vue.component('config_edu_person_scoped_affiliation', {
  props: ['config'],
  template: `
  <div class="form-group">
    <label>Attribute field</label>
    <input type="text" v-model="config.data.field" class="form-control" required />
  </div>`,
  methods: {},
});

Vue.component('config_map', {
  props: ['config'],
  template: `
  <div>
    <div class="form-group">
      <label>Attribute field</label>
      <input type="text" v-model="config.data.field" class="form-control" required />
    </div>

    <div v-for="(item,idx) in config.data.map" class="form-group">
      <div class="col-md-5">
        <input type="text" v-model="item.key" class="form-control" />
      </div>
      <div class="col-md-5">
        <input type="text" v-model="item.value" class="form-control" />
      </div>
      <div class="col-md-2">
        <button v-on:click="remove(idx, $event)" class="btn btn-danger pull-right">-</button>
      </div>
      <br/>
    </div>
    <div class="form-group col-md-12">
      <button v-on:click="add" class="btn btn-primary pull-right">+</button>
    </div><br />
  </div>`,
  methods: {
    add: function (e) {
      e.preventDefault();
      if (this.config.data.map) {
        this.config.data.map.push({ key: '', value: '' });
      } else {
        this.config.data = Object.assign({}, this.config.data, {
          map: [{ key: '', value: '' }],
        });
      }
    },
    remove: function (idx, e) {
      e.preventDefault();
      this.config.data.map.splice(idx, 1);
    },
  },
});
