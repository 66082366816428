'use strict';

function ListeningPromise(executor) {
  var promise = new Promise(function (resolve, reject) {
    return executor(resolve, reject);
  });

  this.default_callback = function () {
    window.location.href = '/noAccess';
  };
  $(this).on('noAccess', this.default_callback);

  promise = promise.catch((data) => {
    if (data && data.code == 401) {
      $(this).trigger({
        type: 'noAccess',
        message: data.message,
      });
    } else {
      throw new Error(data);
    }
    $(this).off('noAccess');
  });

  promise = promise.then((data) => {
    $(this).off('noAccess');
    return data;
  });

  promise.__proto__ = ListeningPromise.prototype;
  return promise;
}

ListeningPromise.prototype.on = function (event, callback) {
  $(this).off(event, this.default_callback);
  $(this).on(event, callback);
  return this;
};

ListeningPromise.__proto__ = Promise;
ListeningPromise.prototype.__proto__ = Promise.prototype;

// Small utility for wrapping asynchronous, Promise-based server-communication. Can be independently tested :)
export default class Remote {
  constructor(url, options) {
    this.baseURL = url;
    this.options = $.extend({}, options);
    return this;
  }

  error_message(xhr) {
    if (xhr.status == 401) {
      return {
        error: true,
        message: 'No Access, could be session timeout',
        code: '401',
      };
    } else if (xhr.responseJSON) {
      return xhr.responseJSON;
    } else {
      return { error: true, message: 'An error occurred', code: xhr.status };
    }
  }

  get(url, data = {}, options = { type: 'json' }) {
    var url = `${this.baseURL}/${url}`;

    return new ListeningPromise((resolve, reject) => {
      $.ajax({
        url: url,
        type: 'GET',
        data: data,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        dataType: options.type,
        success: function (data) {
          resolve(data);
        },
        statusCode: {
          401: (xhr) => {
            reject(this.error_message(xhr));
          },
        },
        error: (xhr) => {
          // Do not reject on 401, handle it in statusCode.
          if (xhr.status != 401) {
            reject(this.error_message(xhr));
          }
        },
      });
    });
  }

  post(url, data, options = { type: 'json' }) {
    var url = `${this.baseURL}/${url}`;
    return new ListeningPromise((resolve, reject) => {
      $.ajax({
        url: url,
        data: data,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        type: 'POST',
        dataType: options.type,
        success: function (data) {
          resolve(data);
        },
        statusCode: {
          401: (xhr) => {
            reject(this.error_message(xhr));
          },
        },
        error: (xhr) => {
          // Do not reject on 401, handle it in statusCode.
          if (xhr.status != 401) {
            reject(this.error_message(xhr));
          }
        },
      });
    });
  }

  post_fileupload(url, data, options = { type: 'json' }) {
    var url = `${this.baseURL}/${url}`;
    return new ListeningPromise((resolve, reject) => {
      $.ajax({
        url: url,
        data: data,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        type: 'POST',
        dataType: options.type,
        enctype: 'multipart/form-data',
        contentType: false,
        processData: false,
        success: function (data) {
          resolve(data);
        },
        statusCode: {
          401: (xhr) => {
            reject(this.error_message(xhr));
          },
        },
        error: (xhr) => {
          // Do not reject on 401, handle it in statusCode.
          if (xhr.status != 401) {
            reject(this.error_message(xhr));
          }
        },
      });
    });
  }
  put(url, data, options = { type: 'json' }) {
    var url = `${this.baseURL}/${url}`;
    return new ListeningPromise((resolve, reject) => {
      $.ajax({
        url: url,
        data: data,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        type: 'PUT',
        dataType: options.type,
        success: function (result) {
          resolve(result);
        },
        statusCode: {
          401: (xhr) => {
            reject(this.error_message(xhr));
          },
        },
        error: (xhr) => {
          // Do not reject on 401, handle it in statusCode.
          if (xhr.status != 401) {
            reject(this.error_message(xhr));
          }
        },
      });
    });
  }

  delete(url, data, options = { type: 'json' }) {
    var url = `${this.baseURL}/${url}`;
    return new ListeningPromise((resolve, reject) => {
      $.ajax({
        url: url,
        data: data,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        type: 'DELETE',
        dataType: options.type,
        success: function (result) {
          resolve(result);
        },
        statusCode: {
          401: (xhr) => {
            reject(this.error_message(xhr));
          },
        },
        error: (xhr) => {
          // Do not reject on 401, handle it in statusCode.
          if (xhr.status != 401) {
            reject(this.error_message(xhr));
          }
        },
      });
    });
  }
}
